/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
// import PropTypes from "prop-types"
// import { useStaticQuery, graphql, withPrefix } from "gatsby"

import Header from "./header"
import Footer from "./footer"
import SkipToMainContent from "./skipToMainContent"
import BackToTop from "./backToTop"
import "./bloglayout.css"
// import { Helmet } from "react-helmet"

const BlogLayout = ({ children }) => {
  // const data = useStaticQuery(graphql`
  //   query SiteTitleQuery {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `)

  return (
    <div className="site">
      <SkipToMainContent />
      <Header siteTitle={`D2i Technology`} />
        <main id="main" tabIndex="-1" className="main-content">{children}</main>
     <Footer />
     <BackToTop />
    </div>
  )
}

// BlogLayout.propTypes = {
//   children: PropTypes.node.isRequired,
// }

export default BlogLayout
